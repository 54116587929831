import React from "react";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import { useModalStore } from "store/useModalStore";
import styled from "styled-components";
import { Button, Typography15, Typography20 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { isIOS } from "utils/utils";
import env from "config/env";

const IPadAppTerminationModal = () => {
  const { hideModal } = useModalStore();
  const { t } = useTranslation("Modal");

  return (
    <ModalPortal target={"body"}>
      <BaseModalContainer
        callback={() => {
          hideModal();
        }}
        closable={true}
      >
        <ModalContainer>
          <EmojiContainer>😢</EmojiContainer>
          <Typography20 text={t("IPadAppTerminationModal.title", { keySeparator: "." })} color={"--gray18"} weight={700} textAlign="center" />
          <Typography15 text={t("IPadAppTerminationModal.description", { keySeparator: "." })} color={"--gray18"} marginTop="8px" textAlign="center" />
          <ButtonWrapper>
            <Button
              text={t("IPadAppTerminationModal.secondaryButton", { keySeparator: "." })}
              callback={() => {
                hideModal();
              }}
              appearance="secondary"
              size="medium"
            />
            <Button
              text={t("IPadAppTerminationModal.primaryButton", { keySeparator: "." })}
              callback={() => {
                window.open(t("IPadAppTerminationModal.primaryLink", { keySeparator: "." }), "_blank");
              }}
              appearance="primary"
              size="medium"
            />
          </ButtonWrapper>
        </ModalContainer>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContainer = styled.div`
  z-index: 9999999;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 28px 20px 16px;
  box-shadow: var(--boxShadow1);
  width: 312px;
`;

const EmojiContainer = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  font-size: 42px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 8px;
  width: 100%;

  && * {
    flex: 1;
  }
`;

export default IPadAppTerminationModal;
