import { escapeHtml } from "./utils";

export function parseMarkdownToParagraph(blocks) {
  let itemText = "";
  if (blocks.type === "paragraph") {
    for (const content of blocks.children) {
      if (content.type === "text") {
        itemText += escapeHtml(content.value);
      } else if (content.type === "emphasis") {
        itemText += "<i>" + escapeHtml(content.children[0].value) + "</i>";
      } else if (content.type === "strong") {
        itemText += "<b>" + escapeHtml(content.children[0].value) + "</b>";
      } else if (content.type === "inlineCode") {
        itemText += '<code class="inline-code">' + escapeHtml(content.value) + "</code>";
      } else if (content.type === "html") {
        itemText += escapeHtml(content.value);
      } else {
        //TODO: log to sentry - all possible blocks must be supported.
        console.log("unknown node type in paragraph", content);
      }
    }
  }
  return {
    data: {
      text: itemText,
    },
    type: "paragraph",
  };
}
