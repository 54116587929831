import React from "react";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import { useModalStore } from "store/useModalStore";
import styled from "styled-components";
import { Button, Typography14, Typography18 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { isIOS } from "utils/utils";
import env from "config/env";
import { useAppSelector } from "hooks";

const NewMobileAppGuideModal = () => {
  const { hideModal } = useModalStore();
  const { t } = useTranslation("Modal");
  const applicationType = useAppSelector((state) => state.slidGlobal.applicationType);

  return (
    <ModalPortal target={"body"}>
      <BaseModalContainer
        callback={() => {
          hideModal();
        }}
        closable={true}
      >
        <ModalContainer>
          <Typography18
            text={t(applicationType === "web" ? "NewMobileAppGuideModalForWeb.title" : "NewMobileAppGuideModal.title", { keySeparator: "." })}
            color={"--gray18"}
            weight={700}
            textAlign="center"
          />
          <Typography14
            text={t(applicationType === "web" ? "NewMobileAppGuideModalForWeb.description" : "NewMobileAppGuideModal.description", { keySeparator: "." })}
            color={"--gray18"}
            marginTop="8px"
            textAlign="center"
          />
          <ButtonWrapper>
            {applicationType === "web" && (
              <Button
                text={t("NewMobileAppGuideModalForWeb.secondaryButton", { keySeparator: "." })}
                callback={() => {
                  hideModal();
                }}
                appearance="secondary"
                size="medium"
              />
            )}
            <Button
              text={t(applicationType === "web" ? "NewMobileAppGuideModalForWeb.primaryButton" : "NewMobileAppGuideModal.primaryButton", { keySeparator: "." })}
              callback={() => {
                isIOS() ? window.open(env.links.slid_new_mobile_ios, "_blank") : window.open(env.links.slid_new_mobile_android, "_blank");
              }}
              appearance="primary"
              size="medium"
            />
          </ButtonWrapper>
        </ModalContainer>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContainer = styled.div`
  z-index: 9999999;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  box-shadow: var(--boxShadow1);
  width: auto;
  min-width: 320px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 8px;
  width: 100%;

  && * {
    flex: 1;
  }
`;

export default NewMobileAppGuideModal;
