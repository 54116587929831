// auth 2.0
import React, { PureComponent, Suspense, lazy } from "react";
import "./App.css";
import "./styles/bootstrap.min.css";
import "react-datetime/css/react-datetime.css";

import { connect } from "react-redux";
import {
  isSlidUser,
  setLang,
  createUser,
  setUserData,
  setApplicationType,
  setExtensionVersion,
  setScreenOrientation,
  setErrorMessage,
  fetchNotifications,
  setIsExtensionInstalled,
  setExtensionId,
  setIsNewUser,
  setIsOnboardingNeededUser,
  fetchGracePeriods,
  setUserTrialData,
  setIsTabletAppOpenedInSplitView,
  setIsUserCapturedByTabletBefore,
  setUserTabletDeviceInfo,
  setTabletOrientation,
  setPendingTrackEvent,
  clearPendingTrackEvents,
  fetchStudyChallengeData,
  setIsSigningUp,
  setIsSigningIn,
} from "redux/actions/slidGlobalActions";

import { setStopSTT, setIsSTTToggledOn } from "redux/actions/sttActions";
import { setIsGuestModeAlertShown, setIsSTTSupported } from "redux/actions/vdocsActions";
import { Redirect, Link } from "react-router-dom";
import { CookiesProvider, withCookies } from "react-cookie";
import { deviceType, isIPad13, isMacOs, isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import env from "config/env";
import packageJson from "../package.json";
import { Hub } from "aws-amplify";
import { sendAmplitudeData } from "utils/amplitude";
import * as Sentry from "@sentry/browser";
import Cohere from "cohere-js";
import { getApplicationType, findLatestOfTwoVersions, checkIsOnboardingNeededUser, getIpadHomeButtonOrNot, getCookie, isTabletApp, isMobileApp, isIOS } from "utils/utils";
import { setChromeExtentionVersionResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import {
  sendChromeExtensionVersionRequestToParentWindow,
  sendMessageFromWebToExtension,
  sendReadyToExitSlidToParentWindow,
  sendVideoPlaceholderPositionToParentWindow,
  sendWebVersionToParentWindow,
} from "utils/extensionInterface/sendToExtension";
import queryString from "query-string";
import Firebase from "utils/firebase/firebase";
import Sweetalert from "sweetalert2";
import styles from "components/VideoDocument/VideoDocumentEditor.module.scss";
import {
  // desktop
  setIframePort,
  setDesktopDefaultCaptureRect,
  setDesktopCaptureRect,
  setSelectedSourceData,
} from "redux/actions/vdocsActions";
import { setDesktopVersion } from "redux/actions/slidGlobalActions";
import ScrollToTop from "ScrollToTop";
import { initEventTracking, setTrackingUserId, setTrackingUserProperties, setTrackingUserPropertyOnce, trackEvent } from "./utils/eventTracking";
import { Modal, Toast } from "@slid/slid-ips";
import { withHooks } from "./withHooks";
import { fetchSubscription, getDayPassData } from "redux/actions/pricingActions";
import { connectPortToExtension } from "utils/extensionInterface/connectPortToExtension";
import { isUserAuthenticated, signUserOut, getCurrentUser } from "utils/auth/cognitoAuthToolkit";
import { getUserLocationInfo } from "utils/utils";
import GlobalStyle from "styles/globalStyles";
import { addPaymentForAppleIAP } from "./utils/pricing/pricing.utils";
import { setHackleExperimentKeyList } from "./utils/hackle";
import { v4 as uuidv4 } from "uuid";
import { showPaymentCancelledModal, showPaymentFailedModal, showPaymentSuccessModal } from "./utils/paymentModal";
import { eventTypes } from "types/eventTracking";
import { UserMembership } from "types/globals";
import SearchModal from "./components/Search/searchModal";
import SlidRouter from "layouts/Router";
import { setIsSTTv2Supported } from "./redux/actions/sttActions";
import ModalSwitcher from "components/modals/ModalSwitcher";
import { setIsAutoNotesSupported, setIsWhisperAutoNotesSupported } from "redux/modules/autoNotesSlice";
import { useModalStore } from "store/useModalStore";

const currentUrl = window.location.toString();
const SLID_WEB_SITE_URL = env.end_point_url.slid_web_site;
const SLID_WEB_SITE_HOST = env.end_point_url.slid_web_site_host;
const SLID_WEB_APP_HOST = env.end_point_url.slid_web_app_host;
const SlidAccessTokenKey = env.token_key.access_token;
const SlidRefreshTokenKey = env.token_key.refresh_token;

// lazy loading components
const FormComponent = lazy(() => import("./components/auth/FormComponent"));
class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRemoteConfigFetched: false,
      featureFlag: {},
      stableVersions: {},
      lang: "en",
      isUserFetched: false,
      isAuthenticated: false,
      auth_type: "",
    };

    // For verifying the user is logged in or not
    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

    this.onSignInComplete = this.onSignInComplete.bind(this);
    this.onSignUpComplete = this.onSignUpComplete.bind(this);
    this.onSignOutComplete = this.onSignOutComplete.bind(this);
    this.extensionReadyForExitMessageCallback = this.extensionReadyForExitMessageCallback.bind(this);

    this.applicationType = null;
    // desktop
    this.onMessage = this.onMessage.bind(this);
    this.sendMessageToPrimary = this.sendMessageToPrimary.bind(this);
    // set application type
    this.applicationType = getApplicationType();
    // if we are redirected here from slid_web_site the application "web" is sent in querystring
    const sourceApplicationType = queryString.parse(this.props.location.search).applicationType;
    if (sourceApplicationType) {
      this.applicationType = sourceApplicationType;
    }
    this.desktopVersion = currentUrl.includes("desktopVersion") ? currentUrl.split("desktopVersion=")[1] : "3.1.2";
    this.deviceType = isIPad13 ? "tablet" : deviceType === "browser" ? "desktop" : deviceType;
    this.serverErrorHandler = this.serverErrorHandler.bind(this);

    // mobile app
    this.onMobileMessage = this.onMobileMessage.bind(this);
    // tablet
    this.onTabletAppMessage = this.onTabletAppMessage.bind(this);
  }

  async componentDidMount() {
    Hub.listen("auth", this.handleAuthStateChange);

    if (this.applicationType === "desktop") {
      window.addEventListener("message", this.onMessage);
      if (getCookie("desktopVersion")) {
        this.desktopVersion = getCookie("desktopVersion");
      }
      this.props.setDesktopVersion(this.desktopVersion);
      Firebase.getFeatureFlags().then((flags) => {
        if (flags && flags.desktop_force_update && this.desktopUpdateNeeded()) {
          this.demandDesktopUpdate();
        }
      });
    }

    // check whether extension is installed or not.
    try {
      await sendMessageFromWebToExtension({
        action: "WEBSITE_TO_BACK_checkExtensionInstallation",
        responseHandler: (response) => {
          if (response?.action === "BACK_TO_WEBSITE_checkExtensionInstallation" && response.data.isExtensionInstalled) {
            this.props.setIsExtensionInstalled(response.data.isExtensionInstalled);
          } else {
            this.props.setIsExtensionInstalled(false);
          }
        },
      });
    } catch (e) {
      this.props.setIsExtensionInstalled(false);
    }

    if (env.currentEnv !== "production" && this.applicationType === "web") {
      this.props.setExtensionId();
    }

    // language setting
    if (queryString.parse(this.props.location.search).lang) {
      await this.updateLang(queryString.parse(this.props.location.search).lang);
    } else if (navigator.language) {
      await this.updateLang(navigator.language);
    } else {
      await this.updateLang("en");
    }

    // Setting tracking code
    await initEventTracking();
    setHackleExperimentKeyList();
    // Setting `source` property if user is coming from specific marketing campaign.
    const marketingCampaignSource = queryString.parse(this.props.location.search).ref;
    if (marketingCampaignSource) setTrackingUserPropertyOnce("source", marketingCampaignSource);
    const previous = queryString.parse(this.props.location.search).previous;
    if (previous) setTrackingUserProperties({ previous });

    if (this.applicationType === "desktop") {
      sendAmplitudeData(`SLID_ENTER_DESKTOP`);
      trackEvent({
        eventType: "Enter desktop app",
        eventProperties: {
          version: packageJson.version,
          desktopVersion: this.desktopVersion,
          currentUrl: window?.location?.toString(),
        },
      });
    }

    try {
      await Firebase.setRemoteConfig();
      this.setState({
        isRemoteConfigFetched: true,
        featureFlag: await Firebase.getFeatureFlags(),
        stableVersions: await Firebase.getStableVersions(),
      });
    } catch (e) {
      this.setState({
        isRemoteConfigFetched: true,
      });
    }
    this.props.setApplicationType(this.applicationType);
    if (this.applicationType === "extension") {
      setChromeExtentionVersionResponseListener({
        responseHandler: (version) => {
          if (!this.props.extensionVersion) {
            this.props.setExtensionVersion(version);
          } else if (findLatestOfTwoVersions(version["version"], this.props.extensionVersion.version) === version["version"]) {
            this.props.setExtensionVersion(version);
          }
        },
      });
      sendWebVersionToParentWindow();
      sendChromeExtensionVersionRequestToParentWindow();

      const extensionVersion = window.localStorage.getItem("extensionVersion");
      if (extensionVersion) {
        this.props.setIsSTTSupported(findLatestOfTwoVersions(extensionVersion, "1.15.0") === extensionVersion);
        this.props.setIsSTTv2Supported(findLatestOfTwoVersions(extensionVersion, "2.3.0") === extensionVersion);
        this.props.setIsAutoNotesSupported(findLatestOfTwoVersions(extensionVersion, "2.4.0") === extensionVersion);
        this.props.setIsWhisperSLTSupported(findLatestOfTwoVersions(extensionVersion, "2.5.0") === extensionVersion);
        this.props.setIsWhisperAutoNotesSupported(findLatestOfTwoVersions(extensionVersion, "2.6.0") === extensionVersion);
        this.props.setIsRealTimeTranscriptSupported(findLatestOfTwoVersions(extensionVersion, "2.7.0") === extensionVersion);
        this.props.setExtensionVersion({ version: extensionVersion });
      }
    }
    if (this.applicationType === "desktop" && findLatestOfTwoVersions(this.desktopVersion, "4.1.0") === this.desktopVersion && !isMacOs) {
      this.props.setIsSTTSupported(true);
      this.props.setIsSTTv2Supported(true);
    }
    // screen orientation setup
    if (window.orientation !== undefined) {
      this.checkScreenOrientation();
      window.addEventListener("orientationchange", this.checkScreenOrientation());
    }
    if (isMobileApp() && isIOS()) {
      window.addEventListener("message", this.onMobileMessage);
    }

    if (isTabletApp()) {
      useModalStore.getState().showModal({ type: "ipad_app_termination" });
      window.addEventListener("message", this.onTabletAppMessage);
    }

    if (this.applicationType === "mobile" || (this.applicationType === "web" && isMobile)) {
      useModalStore.getState().showModal({ type: "new_mobile_app_guide" });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.errorMessage) {
        if (this.applicationType === "extension") {
          window.location = window.location.pathname;
        } else {
          window.location.reload(false);
        }
      }
    }

    // api error message reaches redux
    if (prevProps.errorMessage !== this.props.errorMessage && this.props.errorMessage) {
      this.handleErrors(this.props.errorMessage);
    }

    // display notifications
    if (prevProps.notifications !== this.props.notifications && this.props.notifications?.length > 0) {
      if (prevProps.notifications?.length !== this.props.notifications?.length) await this.props.showNotification();
    }

    // user data changes
    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData) {
        this.props.setIsOnboardingNeededUser(checkIsOnboardingNeededUser(this.props.userData));
        this.initializeDataTracking();
        this.props.fetchGracePeriods();
      }

      if (this.props.userData && !this.props.userData.is_temporary) {
        await this.props.fetchStudyChallengeData();
        await this.props.fetchNotifications();
      }
      if (this.props.userData && this.props.userData.payment === "trial") {
        const isAuthenticated = await isUserAuthenticated();
        if (isAuthenticated) await this.props.setUserTrialData();
      }
      //do fetchSubscription for subscription membership(basic, pro, standard, premium)
      if (this.props.userData && [UserMembership.basic, UserMembership.pro, UserMembership.premium, UserMembership.standard].includes(this.props.userData.payment)) {
        await this.props.fetchSubscription();
      }

      if (this.props.userData) {
        await this.props.getDayPassData();
      }
    }

    // show next notification if the modal is closed
    if (prevProps.modalOn !== this.props.modalOn) {
      if (!this.props.modalOn) {
        await this.props.showNotification();
      }
    }

    //
    if (this.props.iframePort !== prevProps.iframePort) {
      this.sendMessageToPrimary({
        type: "IFRAME_RECEIVED_PORT",
        payload: null,
      });
    }

    //
    if (this.props.extensionId !== prevProps.extensionId && !this.props.userData) {
      if (env.currentEnv !== "production") this.setAmplitudeDeviceIdFromExtension();
    }

    if (isTabletApp()) {
      window.addEventListener("message", this.onTabletAppMessage);
    }

    if (this.props.applicationType === "extension" && (this.props.editorInstance !== prevProps.editorInstance || this.props.isSTTActive !== prevProps.isSTTActive)) {
      // when user close the extension by clicking close button, convert all STT blocks to normal blocks

      window.removeEventListener("message", this.extensionReadyForExitMessageCallback);
      window.addEventListener("message", this.extensionReadyForExitMessageCallback);
    }
  }

  async extensionReadyForExitMessageCallback(message) {
    try {
      const receivedData = message.data;
      if (!receivedData) return;

      if (receivedData.action === "CONTENT_TO_IFRAME_readyForExit") {
        if (!this.props.editorInstance) {
          sendReadyToExitSlidToParentWindow();
          return;
        }

        if (this.props.isReadOnly) {
          sendReadyToExitSlidToParentWindow();
          return;
        }

        const noteData = await this.props.editorInstance.save();
        const isSTTBlockExist = noteData.blocks.some((block) => block.type === "smartLiveText");

        if (!isSTTBlockExist) {
          sendReadyToExitSlidToParentWindow();
        } else {
          if (this.props.isSTTActive) {
            this.props.setStopSTT();
            this.props.setIsSTTToggledOn(false);
          }
          setTimeout(() => {
            sendReadyToExitSlidToParentWindow();
          }, 1000);
        }
      }
    } catch (e) {
      // ignore
      console.log("setIsReadyForExitListener message parsing error");
      console.error(e);
    }
  }

  componentWillUnmount() {
    Hub.remove("auth");
    // screen orientation remove
    window.removeEventListener("orientationchange", this.checkScreenOrientation());
    if (findLatestOfTwoVersions(this.desktopVersion, "1.0.2") === this.desktopVersion) {
      window.removeEventListener("message", this.onMessage);
    }

    if (isMobileApp() && isIOS()) {
      window.addEventListener("message", this.onMobileMessage);
    }
    if (isTabletApp()) {
      window.removeEventListener("message", this.onTabletAppMessage);
    }
  }

  onMessage(event) {
    /*
    Desktop message listener1 (another listener is in VideoDocumentEditor)
    */
    switch (event.data.type) {
      case "PRIMARY_TO_IFRAME_SEND_PORT":
        this.props.setIframePort(event.ports[0]);
        break;
      case "PRIMARY_TO_IFRAME_LOGOUT":
      case "MYDOCS_TO_IFRAME_LOGOUT":
        this.signOutCognitoUser();
        break;
      default:
        return;
    }
  }

  // For handling tablet capture
  async onTabletAppMessage(event) {
    if (event.data && typeof event.data === "string") {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case "APP_TO_WEB_sendDeviceOrientation":
          this.props.setTabletOrientation(data.payload);
          break;
        case "APP_TO_WEB_sendIsSplitView":
          this.props.setIsTabletAppOpenedInSplitView(data.payload === "true" ? true : false);
          break;
        case "APP_TO_WEB_sendIsUserCapturedByTabletBefore":
          this.props.setIsUserCapturedByTabletBefore(data.payload === "true" ? true : false);
          break;
        case "APP_TO_WEB_sendUserTabletDeviceInfo":
          const tabletDeviceInfo = JSON.parse(data.payload);
          this.props.setUserTabletDeviceInfo({
            tabletHomeButtonType: getIpadHomeButtonOrNot(tabletDeviceInfo.tabletDeviceId),
            tabletSystemVersion: tabletDeviceInfo.tabletSystemVersion,
          });
          break;
        default:
          return;
      }
    }
  }

  // For handling IAP
  async onMobileMessage(event) {
    if (event.data && typeof event.data === "string") {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case "APP_TO_WEB_purchasePlanSuccess":
          const paymentData = JSON.parse(data.payload);
          // Before v2.0.1 mobile app, IAP logic handles old pricing plan.
          // So, for daypass/standard/premium, they are all sent as membership: pro.
          // See more details in here. https://slidhq.slack.com/archives/C01HQRX997X/p1681193433544319
          const isOldPricingPlan = paymentData.membership?.includes("pro");
          const productId = paymentData.paymentInfo?.productId;
          const isDayPassPayment = productId?.includes("24h_pass");
          if (isOldPricingPlan) {
            let membership = null;
            let length = null;
            if (isDayPassPayment) {
              membership = UserMembership.standard; // For daypass, membership will be ignored in server if there is `dayPassCount` in paymentInfo.
              if (productId?.includes("1ea")) {
                length = 1;
              } else if (productId?.includes("5ea")) {
                length = 5;
              }
            } else {
              if (productId.includes("1m")) {
                length = 1;
              } else {
                length = 12;
              }

              if (productId?.includes("standard")) {
                membership = UserMembership.standard;
              } else if (productId?.includes("premium")) {
                membership = UserMembership.premium;
              }
            }
            let paymentPayload = {
              paymentInfo: {
                ...paymentData.paymentInfo,
                dayPassCount: isDayPassPayment ? length : undefined, // For server easily know it is daypass or not.
              },
              paymentGateway: "apple",
              paymentKey: `apple_iap_payment_key_${isDayPassPayment ? UserMembership.daypass : membership}_${length}_${uuidv4()}`,
              amount: paymentData.paymentInfo?.price,
              membership: membership, // For using day_pass as standard on backend, no change in here.
              length: length,
              isRecurring: false, // if isOneTimePayment, set as not recurring payment system,
              showModal: this.props.showModal,
              closeModal: this.props.closeModal,
              deviceType,
            };
            try {
              await addPaymentForAppleIAP(paymentPayload);
              this.props.closeModal();
              showPaymentSuccessModal({
                deviceType: "mobile",
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
                parameter: {
                  membership: isDayPassPayment ? UserMembership.daypass : membership,
                  length: length,
                },
              });
            } catch (error) {
              Sentry.withScope((scope) => {
                scope.setExtra("paymentPayload", paymentPayload);
                scope.setLevel(error.level ? error.level : "info");
                Sentry.captureMessage("APPLE_IAP_FAILED");
              });
              this.props.closeModal();
              showPaymentFailedModal({
                deviceType: "mobile",
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
              });
            }
          } else {
            let paymentPayload = {
              paymentInfo: paymentData.paymentInfo,
              paymentGateway: "apple",
              paymentKey: `apple_iap_payment_key_${isDayPassPayment ? UserMembership.daypass : paymentData.membership}_${paymentData.length}_${uuidv4()}`,
              amount: paymentData.amount,
              membership: paymentData.membership, // For using day_pass as standard on backend, no change in here.
              length: paymentData.length,
              isRecurring: false, // if isOneTimePayment, set as not recurring payment system,
              showModal: this.props.showModal,
              closeModal: this.props.closeModal,
              deviceType,
            };
            try {
              await addPaymentForAppleIAP(paymentPayload);
              this.props.closeModal();

              showPaymentSuccessModal({
                deviceType: "mobile",
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
                parameter: {
                  membership: isDayPassPayment ? UserMembership.daypass : paymentData.membership,
                  length: paymentData.length,
                },
              });
            } catch (error) {
              Sentry.withScope((scope) => {
                scope.setExtra("paymentPayload", paymentPayload);
                scope.setExtra("error", error);
                scope.setExtra("dataFromMobile", data);
                scope.setExtra("isOldPricingPlan", isOldPricingPlan);
                scope.setExtra("isDayPassPayment", isDayPassPayment);

                scope.setLevel(error.level ? error.level : "info");
                Sentry.captureMessage("APPLE_IAP_FAILED");
              });
              this.props.closeModal();
              showPaymentFailedModal({
                deviceType: "mobile",
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
              });
            }
          }

          break;
        case "APP_TO_WEB_purchasePlanCanceledByUser":
          showPaymentCancelledModal({
            showModal: this.props.showModal,
            closeModal: this.props.closeModal,
            deviceType: "mobile",
          });
          break;
        case "APP_TO_WEB_purchasePlanError":
          showPaymentFailedModal({ showModal: this.props.showModal, closeModal: this.props.closeModal, deviceType: "mobile" });
          break;
        default:
          return;
      }
    }
  }

  sendMessageToPrimary(message) {
    /*
    Sends messages to the primary window of Slid Desktop
    */
    if (this.props.iframePort) {
      this.props.iframePort.postMessage(message);
      return true;
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_DESKTOP_IFRAME_DID_NOT_RECEIVE_PORT");
      });
      return false;
    }
  }

  desktopUpdateNeeded = () => {
    if (this.applicationType !== "desktop") return false;
    if (!this.desktopVersion || typeof this.desktopVersion !== "string") return false;
    if (this.desktopVersion.split(".").length !== 3) return false;
    const currentDesktopVersion = this.desktopVersion;
    const requiredDesktopVersion = env.minimum_supported_desktop_version;
    // return whether the current version is older than required version
    return findLatestOfTwoVersions(currentDesktopVersion, requiredDesktopVersion) === requiredDesktopVersion;
  };

  demandDesktopUpdate = () => {
    Sweetalert.fire({
      target: `.${styles[`video-document-container`]}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: this.props.lang === "ko" ? "업데이트가 필요합니다!" : "Update required!",
      html:
        this.props.lang === "ko"
          ? `
                아래 '업데이트' 버튼을 누르면 <br/>
                브라우저에서 최신 버전을 다운로드됩니다
            `
          : `
                Click the "Update" button below to download the latest version
                form the browser
            `,
      icon: "info",
      confirmButtonText: this.props.lang === "ko" ? "업데이트" : "Update",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: () => {
        this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_OPEN_URL",
          payload: `${SLID_WEB_SITE_URL}/desktop-update-guide`,
        });
        return false; // prevent closing the popup so that legacy app can not be used
      },
    }).then((result) => {});
  };

  // screen orientation check method
  checkScreenOrientation() {
    try {
      const screenOrientation = window?.screen?.orientation?.type?.includes("portrait") ? "vertical" : "horizontal";

      this.props.setScreenOrientation(screenOrientation);
    } catch (e) {}
  }

  async updateLang(lang) {
    lang = lang.toLowerCase();
    if (lang === "en-us") lang = "en";
    if (lang === "ko-kr") lang = "ko";
    if (lang !== "ko" && lang !== "en") {
      lang = "en";
    }
    this.props.i18n.changeLanguage(lang);
    await this.setState({
      lang: lang,
    });
    document.documentElement.setAttribute("lang", lang);
    this.props.setLang(lang);
  }

  async handleAuthStateChange(data) {
    switch (data.payload?.event) {
      case "signIn":
        await this.onSignInComplete();
        break;
      case "signUp":
        break;
      case "signOut":
        await this.onSignOutComplete();
        break;
      case "oAuthSignOut":
        await this.onOAuthSignOutComplete();
        break;
      case "cognitoHostedUI":
      case "signIn_failure":
      case "tokenRefresh":
      case "tokenRefresh_failure":
      case "autoSignIn":
      case "autoSignIn_failure":
      case "configured":
        break;
      default:
        break;
    }
  }

  removeSlidTokensInCookie() {
    this.props.cookies.remove(SlidAccessTokenKey, {
      domain: `${env.currentEnv === "production" ? "." + SLID_WEB_APP_HOST : SLID_WEB_APP_HOST}`,
      path: "/",
    });
    this.props.cookies.remove(SlidAccessTokenKey, {
      domain: `${env.currentEnv === "production" ? "." + SLID_WEB_SITE_HOST : SLID_WEB_SITE_HOST}`,
      path: "/",
    });
    if (env.currentEnv.includes("test")) {
      this.props.cookies.remove(SlidAccessTokenKey, {
        domain: "." + "slid.cc",
        path: "/",
      });
    }
    window.localStorage.removeItem(SlidAccessTokenKey);
    this.props.cookies.remove(SlidRefreshTokenKey, {
      domain: `${env.currentEnv === "production" ? "." + SLID_WEB_APP_HOST : SLID_WEB_APP_HOST}`,
      path: "/",
    });
    this.props.cookies.remove(SlidRefreshTokenKey, {
      domain: `${env.currentEnv === "production" ? "." + SLID_WEB_SITE_HOST : SLID_WEB_SITE_HOST}`,
      path: "/",
    });
    if (env.currentEnv.includes("test")) {
      this.props.cookies.remove(SlidRefreshTokenKey, {
        domain: "." + "slid.cc",
        path: "/",
      });
    }
    window.localStorage.removeItem(SlidRefreshTokenKey);
  }

  async onSignInComplete() {
    const user = await getCurrentUser();
    const loginType = user.signInUserSession.idToken.payload.identities ? user.signInUserSession.idToken.payload.identities[0].providerType : "email";
    let userCountry;
    try {
      const locationInfo = await getUserLocationInfo();
      userCountry = locationInfo.name;
    } catch (e) {
      // do nothing
    }
    // ask backend if user is new or note
    const isSlidUser = await this.props.isSlidUser();
    // if user is new, create them on backend
    if (!isSlidUser) {
      this.setState({ auth_type: "sign-up" });
      const userData = await this.props.createUser({
        cognitoIdToken: user.signInUserSession.idToken.jwtToken,
        loginType: loginType,
        locale: this.props.lang || "en",
        userCountry: userCountry,
      });
      const userEmail = user.attributes.email.split("@")[1];
      this.props.setPendingTrackEvent({
        eventType: "User sign-up",
        eventProperties: {
          email: userEmail,
          loginType: loginType,
          isExtensionInstalled: this.props.isExtensionInstalled,
        },
      });
      this.props.setPendingTrackEvent({
        eventType: eventTypes.success.START_FREE_TRIAL,
        eventProperties: {
          freeTrialLength: 14,
          version: "14-day study challenge",
        },
      });
      if (userData && !userData.error_message) this.props.setIsSigningUp(true);
    } else if (isSlidUser) {
      this.setState({ auth_type: "sign-in" });
      const userEmail = user.attributes?.email.split("@")[1];
      sendAmplitudeData("SLID_1_SINGIN_USER", {
        email: userEmail,
        loginType: loginType,
      });
      this.props.setPendingTrackEvent({
        eventType: "User sign-in",
        eventProperties: {
          email: userEmail,
          loginType: loginType,
          isExtensionInstalled: this.props.isExtensionInstalled,
        },
      });
      this.props.setIsSigningIn(true);
    }
  }

  async onSignUpComplete() {
    // this is useless
  }

  async onSignOutComplete() {
    this.removeSlidTokensInCookie();
    window.localStorage.removeItem("slid_extension_version");
    window.location.reload();
  }

  async onOAuthSignOutComplete() {
    this.removeSlidTokensInCookie();
    window.localStorage.removeItem("slid_extension_version");
  }

  async initializeDataTracking() {
    const isAuthenticated = await isUserAuthenticated();
    if (!isAuthenticated) {
      this.props.setUserData(null);
      if (env["env"] === "production" && this.applicationType === "web") this.setAmplitudeDeviceIdFromExtension();
      return;
    }
    if (!this.props.userData || this.props.userData.is_temporary) return;
    // For configuring amplitude user id
    const user_key = this.props.userData.user_key;
    const user_email = this.props.userData.email;
    const user_payment = this.props.userData.payment;
    const user_username = this.props.userData.username;
    if (user_key) {
      setTrackingUserId(user_key);
      setTrackingUserProperties({
        ...this.props.userData,
      });

      // apply any pending event
      if (this.props.pendingTrackEvents) {
        this.props.pendingTrackEvents.forEach((event) => {
          trackEvent(event);
        });
        this.props.clearPendingTrackEvents();
      }

      if (env.currentEnv === "production") {
        let displayWebApp = `[🕸v${packageJson.version}]`;
        let displayDesktop = "";
        let displayExtension = "";
        if (this.props.applicationType === "desktop" && this.props.desktopVersion) displayDesktop = `[🖥v${this.props.desktopVersion}]`;
        if (this.props.applicationType === "extension") {
          const extensionVersion = window.localStorage.getItem("extensionVersion");
          if (extensionVersion) displayExtension = `[🔌v${extensionVersion}]`;
          else if (this.props.extensionVersion) displayExtension = `[🔌v${this.props.extensionVersion.version}]`;
        }
        Cohere.init(env.common_api_key.cohere);
        Cohere.identify(user_key, {
          // example: [🕸v1.0.0][🖥v1.0.0][🔌v1.0.0][paid] username
          displayName: displayWebApp + displayDesktop + displayExtension + `[${user_payment}] ${user_username}`,
          email: user_email,
        });
      }
    }
    this.setState({ isUserFetched: true });
  }

  async signOutCognitoUser() {
    await signUserOut();
  }

  serverErrorHandler(error) {
    if (error.custom_message) {
      if (error.sentry_message) {
        Sentry.withScope((scope) => {
          scope.setExtra("location", this.props.location);
          scope.setLevel(error.level ? error.level : "info");
          if (error.additionalMessage) scope.setExtra("additionalMessage", error.additionalMessage);
          Sentry.captureMessage(error.sentry_message);
        });
      }
      this.props.setErrorMessage(error.custom_message);
    } else {
      this.props.setErrorMessage(null);
    }
  }

  setAmplitudeDeviceIdFromExtension() {
    setTrackingUserId(null);
    setTrackingUserProperties({});
    const externalPort = connectPortToExtension();
    if (externalPort) {
      externalPort.onMessage.addListener((message) => {
        if (message) {
          const { action, data } = message;
          if (action === "BACK_TO_WEB_getAmplitudeDeviceId" && data.deviceId) {
            initEventTracking(data.deviceId);
          }
        }
      });
      externalPort.postMessage({
        action: "WEB_TO_BACK_getAmplitudeDeviceId",
      });
    }
  }

  async handleErrors(errorMessage) {
    this.props.setErrorMessage(null);
    // log the error to sentry
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage(errorMessage);
    });
    switch (errorMessage) {
      // common
      // auth related errors are already handled in server interfaces
      case "METHOD_NOT_ALLOWED":
        break;
      case "INSUFFICIENT_PRIVILEGES":
        const notifications = await this.props.fetchNotifications();
        if (notifications.error_message) return;
        if (notifications.length === 0 && !this.props.modalOn) {
          return this.props.showInsufficientPrivilegeModal(true);
        }
        break;
      case "NETWORK_ERROR":
        // network issue on client side
        break;
      case "NO_RESPONSE_FROM_SERVER":
        // server could not respond
        break;
      // ai sliddy api
      case "AI_SLIDDY_FAILED_TO_PROCESS_REQUEST":
        // 500 on aisliddy post
        break;
      // apple IAP api
      case "APPLE_IAP_INCORRECT_DATA_FORMAT":
        // post apple iam FAILED
        break;
      // clipsApi
      case "POST_CLIP_INVALID_DATA":
        // could not register clip
        break;
      case "CLIP_NOT_FOUND":
        // could not find clip with clip_key to update it
        break;
      case "UPDATE_CLIP_BAD_REQUEST":
        // some fields are missing
        break;
      // day-pass api
      case "DAY_PASS_ALREADY_ACTIVE":
        break;
      case "NO_DAY_PASSES_FOUND":
        break;
      case "DAY_PASS_NOT_ASSOCIATED_WITH_PAYMENT":
        break;
      // deleted documents api
      case "NO_DELETED_DOCUMENTS_OR_FOLDERS_FOUND":
        break;
      // documents api
      case "DOCUMENT_OR_FOLDER_NOT_FOUND":
        break;
      case "DOCUMENT_OR_FOLDER_IS_DELETED":
        break;
      case "ANAUTHORIZED_USER":
        break;
      case "ERROR_GETTING_DOCUMENT":
        break;
      case "ERROR_PERMANENTLY_DELETING_DOCUMENT":
        break;
      case "ERROR_UPDATING_DOCUMENT":
        // some error when updating doc
        break;
      case "ERROR_GETTING_CLEAN_TEXT_FROM_HTML":
        // api error when getting clean text from html
        break;
      case "INVALID_PARENT_KEY":
        // when moving a file to a folder but parent_key is self, is not a folder or does not exist
        break;
      // grace period api: no special error
      // history api
      case "FOLDER_HISTORY_NOT_SUPPORTED":
        // can not fech folder history
        break;
      case "HISTORY_DOCUMENT_NOT_FOUND":
        // can not find document, for which to fetch history
        break;
      case "HISTORY_UNAUTHORIZED_USER":
        // user does not own the document
        break;
      case "FOLDER_HISTORY_NOT_SUPPORTED":
        // can not create folder history
        break;
      case "INVALID_HISTORY_DATA":
        break;
      case "FAILED_TO_CREATE_HISTORY":
        break;
      // link preview api  : no special error
      // notifications api: no special error
      case "NOTIFICATION_REQUIRED_FIELD_MISSING":
        break;
      // ocr api
      case "OCR_REGISTER_FAILED":
        // duplicate ocr register or clip key missing
        break;
      case "CLIP_NOT_FOUND":
        // clip not found when getting ocr results
        break;
      // payment api
      case "STRIPE_MISSING_PRICING_ID":
        // no pricing id in request
        break;
      case "STRIPE_MISSING_IS_RECURRING":
        // no is_recurring in request
        break;
      case "STRIPE_CREATE_CHECKOUT_ERROR":
        // stripe error when creating checkout session
        break;
      // pdf download api
      case "PDF_DOWNLOAD_INVALID_DOCUMENT_KEY":
        break;
      case "PDF_DOWNLOAD_DOCUMENT_OR_FOLDER_NOT_FOUND":
        break;
      case "PDF_DOWNLOAD_UNAUTHORIZED_USER":
        break;
      case "PDF_DOWNLOAD_DOCUMENT_IS_DELETED":
        break;
      case "PDF_DOWNLOAD_ERROR":
        break;
      case "PDF_DOWNLOAD_NOT_FOUND":
        // no pdf download task found
        break;
      // referrals api
      case "REFERRAL_CODE_NOT_EXISTS":
        break;
      // search api
      case "INVALID_SEARCH_CATEGORY":
        break;
      case "INVALID_SEARCH_TARGET":
        break;
      case "INVALID_SEARCH_PARAMETERS":
        break;
      // share document api
      case "SHARE_DOCUMENT_NOT_FOUND":
        break;
      case "SHARE_DOCUMENT_IS_DELETED":
        break;
      case "SHARE_DOCUMENT_NOT_PUBLIC":
        break;
      case "SHARE_FOLDERS_NOT_SUPPORTED":
        break;
      case "SHARE_DOCUMENT_ERROR":
        break;
      // slid expert api
      case "SLID_EXPERT_INVALID_DATA":
        break;
      // subscription api
      case "MISSING_SUBSCRIPTION_KEY":
        break;
      case "INVALID_SUBSCRIPTION_KEY":
        break;
      case "UNSUPPORTED_PAYMENT_GATEWAY":
        break;
      case "SUBSCRIPTION_PUT_ERROR":
        break;
      case "SUBSCRIPTION_NOT_FOUND":
        break;
      // trial api
      case "TRIAL_NOT_FOUND":
      // user exists api: no special error
      // user api
      case "TEMP_USER_NOT_SUPPORTED":
        break;
      case "FAILED_TO_CREATE_TRIAL":
        break;
      case "FAILED_TO_DELETE_USER_FROM_COGNITO":
        break;
      case "FAILED_TO_DELETE_USER_FROM_SLID_DB":
        break;
      case "FAILED_TO_DELETE_SUBSCRIPTION":
        break;
      case "FAILED_TO_DELETE_USER":
        break;
      case "CREATE_USER_INVALID_COGNITO_ID_TOKEN":
        break;
      case "CREATE_USER_INVALID_DATA":
        break;
      // video api
      case "VIDEO_REGISTER_DOCUMENT_KEY_NOT_PROVIDED":
        break;
      case "VIDEO_REGISTER_INVALID_DATA_FOR_VIDEOBOARD":
        break;
      case "VIDEO_REGISTER_INVALID_DATA_DOCUMENT_DOESNT_EXISTS":
        break;
      case "VIDEO_REGISTER_INVALID_DATA":
        break;
      case "VIDEO_REGISTER_ERROR_REGISTERING_VIDEO":
        break;
      // default
      default:
        break;
    }
  }

  renderErrorDOM() {
    // hide video in extension
    if (this.applicationType === "extension") {
      sendVideoPlaceholderPositionToParentWindow({
        videoPlaceholderPosition: {
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        },
      });
    }

    switch (this.props.errorMessage) {
      case "USER_AUTH_FAIL":
      case "USER_TOKEN_EXPIRE":
      case "USER_NOT_FOUND":
      case "USER_TOKEN_INVALID":
        return (
          <div className={`error-content-container d-flex flex-column justify-content-center align-items-center`}>
            <div className="spinner-border" role="status">
              <span className="sr-only">{this.state.lang === "ko" ? `사용자 인증 중...🧐` : `Authenticating...🧐️`}</span>
            </div>
            <span className={`loading-text mt-3`}>{this.state.lang === "ko" ? `사용자 인증 중...🧐` : `Authenticating...🧐️`}</span>
            <br />
            {this.applicationType === "extension" ? (
              <div>
                <button
                  className={`btn btn-primary mt-3`}
                  onClick={() => {
                    if (this.applicationType === "extension") {
                      window.location = window.location.pathname;
                    } else {
                      window.location.reload(false);
                    }
                  }}
                >
                  {this.state.lang === "ko" ? `새로고침!` : `Refresh`}
                </button>
              </div>
            ) : (
              <Link
                to={"/docs"}
                onClick={() => {
                  this.serverErrorHandler({
                    custom_message: null,
                  });
                }}
              >
                {this.props.t("GoToMyNotes", { ns: "common" })}
              </Link>
            )}
          </div>
        );

      case "USER_NOT_REGISTERED":
        if (this.state.isAuthenticated) {
          return (
            <div className={`error-content-container d-flex flex-column justify-content-center align-items-center`}>
              <div
                className={`pointer`}
                onClick={() => {
                  window.location.assign(`${SLID_WEB_SITE_URL}`);
                }}
              >
                <img className={`logo`} src={`/src/logo/slid_logo.png`} alt={``} />
                <img className={`logo-text`} src={`/src/logo/slid_logo_text.png`} alt={``} />
              </div>
              <h4>{this.state.lang === "ko" ? `해당 노트에 접근 권한이 없습니다. 😅` : `You don't have access to this document. 😅`}</h4>
              <span className={`text-muted`}>
                {this.state.lang === "ko" ? `도움이 필요하신가요?` : `Need help?`} 👉{" "}
                <a href={`http://slid.channel.io/`} target={`_blank`}>
                  {this.state.lang === "ko" ? `채팅 문의하기` : `Chat with us`}
                </a>
              </span>
              <br />
              {this.applicationType === "extension" ? (
                <a href={`/docs`} target={`_blank`}>
                  {this.props.t("GoToMyNotes", { ns: "common" })}
                </a>
              ) : (
                <Link
                  to={"/docs"}
                  onClick={() => {
                    this.serverErrorHandler({
                      custom_message: null,
                    });
                  }}
                >
                  {this.props.t("GoToMyNotes", { ns: "common" })}
                </Link>
              )}
            </div>
          );
        } else {
          return (
            <div className={`d-flex flex-column justify-content-center align-items-center`}>
              <div className={`card`}>
                <FormComponent stage={"SIGNIN"} />
              </div>
            </div>
          );
        }

      case "FORCE_SIGN_UP":
        return <Redirect to={`/sign-up`} />;
      case "DOCUMENT_NOT_FOUND":
      case "DOCUMENT_NOT_PUBLIC":
      case "VIDEO_NOT_FOUND":
        this.props.history.push("/404?error=DOCUMENT_NOT_FOUND");
        break;
      case "NETWORK_ERROR":
        return (
          <div className={`error-content-container col-12 col-md-6 d-flex flex-column justify-content-center align-items-center`}>
            <b>{this.state.lang === "ko" ? `서버와 연결이 끊겼습니다. 😢` : `Oops! We lost connection. 😢`}</b>
            <br />
            {this.state.lang === "ko" ? `새로고침을 하고 다시 한번 시도해주세요!` : `Please refresh the page and try again.`}
            <br />
            {this.applicationType === "extension" ? (
              <div>
                <button
                  className={`btn btn-primary mt-3`}
                  onClick={() => {
                    if (this.applicationType === "extension") {
                      window.location = window.location.pathname;
                    } else {
                      window.location.reload(false);
                    }
                  }}
                >
                  {this.state.lang === "ko" ? `새로고침!` : `Refresh`}
                </button>
              </div>
            ) : (
              <Link
                to={"/docs"}
                onClick={() => {
                  this.serverErrorHandler({
                    custom_message: null,
                  });
                }}
              >
                {this.props.t("GoToMyNotes", { ns: "common" })}
              </Link>
            )}
          </div>
        );
      case "SERVER_ERROR":
        return (
          <div className={`error-content-container col-12 col-md-6 d-flex flex-column justify-content-center align-items-center`}>
            <b>{this.state.lang === "ko" ? `서버에서 오류가 발생했습니다. 😢` : `Oops! Something went wrong. 😢`}</b>
            <br />
            {this.state.lang === "ko" ? `새로고침을 하고 다시 한번 시도해주세요!` : `Please refresh the page and try again.`}
            <br />

            {this.applicationType === "extension" ? (
              <div>
                <button
                  className={`btn btn-primary mt-3`}
                  onClick={() => {
                    if (this.applicationType === "extension") {
                      window.location = window.location.pathname;
                    } else {
                      window.location.reload(false);
                    }
                  }}
                >
                  {this.state.lang === "ko" ? `새로고침!` : `Refresh`}
                </button>
              </div>
            ) : (
              <a href={`https://slid.channel.io/`} target={`_blank`}>
                {this.state.lang === "ko" ? `실시간 채팅 문의` : `Live chat support`}
              </a>
            )}
          </div>
        );
      default:
        return (
          <div className={`error-content-container col-12 col-md-6 d-flex flex-column justify-content-center align-items-center`}>
            <b>{this.state.lang === "ko" ? `서버에서 오류가 발생했습니다. 😢` : `Oops! Something went wrong. 😢`}</b>
            <br />
            {this.state.lang === "ko" ? `새로고침을 하고 다시 한번 시도해주세요!` : `Please refresh the page and try again.`}
            <br />
            {this.applicationType === "extension" ? (
              <div>
                <button
                  className={`btn btn-primary mt-3`}
                  onClick={() => {
                    if (this.applicationType === "extension") {
                      window.location = window.location.pathname;
                    } else {
                      window.location.reload(false);
                    }
                  }}
                >
                  {this.state.lang === "ko" ? `새로고침!` : `Refresh`}
                </button>
              </div>
            ) : (
              <Link
                to={"/docs"}
                onClick={() => {
                  this.serverErrorHandler({
                    custom_message: null,
                  });
                }}
              >
                {this.props.t("GoToMyNotes", { ns: "common" })}
              </Link>
            )}
          </div>
        );
    }
  }

  render() {
    return (
      <CookiesProvider>
        <GlobalStyle isMobile={deviceType === "mobile"} location={window.location.pathname} style={this.applicationType === "slidpocket" && { display: "none" }} />
        <ModalSwitcher />
        <div slid-cy={`toast-wrapper`}>
          <Toast />
        </div>
        <div slid-cy={`modal-wrapper`}>
          <Modal />
        </div>
        <SearchModal />
        {this.state.lang === "ko" ? (
          <Helmet>
            <title>Slid | 온라인 강의 속 지식을 내 것으로</title>
          </Helmet>
        ) : (
          <Helmet>
            <title>Slid | Capture your knowledge</title>
          </Helmet>
        )}
        <div className={`App`}>
          <ScrollToTop />
          {this.props.errorMessage && this.props.errorMessage !== "INSUFFICIENT_PRIVILEGES" ? (
            <div className={`App-Component error-container d-flex flex-column align-items-center justify-content-center`}>{this.renderErrorDOM()}</div>
          ) : this.state.isRemoteConfigFetched ? (
            <Suspense
              fallback={
                <div className={`main-loading-container d-flex flex-column justify-content-center align-items-center`} style={{ display: this.applicationType === "slidpocket" ? "none" : "flex" }}>
                  <div>
                    <img className={`logo`} src={`/src/logo/slid_logo.png`} alt={``} />
                    <img className={`logo-text`} src={`/src/logo/slid_logo_text.png`} alt={``} />
                  </div>
                  <div className="spinner-border mt-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span className={`mt-3`}>Loading...</span>
                </div>
              }
            >
              <SlidRouter {...this.props} lang={this.state.lang} auth_type={this.state.auth_type} serverErrorHandler={this.serverErrorHandler} />
            </Suspense>
          ) : (
            <div className={`main-loading-container d-flex flex-column justify-content-center align-items-center`} style={{ display: this.applicationType === "slidpocket" ? "none" : "flex" }}>
              <div>
                <img className={`logo`} src={`/src/logo/slid_logo.png`} alt={``} />
                <img className={`logo-text`} src={`/src/logo/slid_logo_text.png`} alt={``} />
              </div>
              <div className="spinner-border mt-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <span className={`mt-3`}>Loading...</span>
            </div>
          )}
        </div>
      </CookiesProvider>
    );
  }
}

const actions = {
  isSlidUser,
  setLang,
  setUserData,
  setIsNewUser,
  setIsOnboardingNeededUser,
  createUser,
  fetchNotifications,
  setApplicationType,
  setExtensionVersion,
  setScreenOrientation,
  setErrorMessage,
  setIsExtensionInstalled,
  fetchSubscription,
  setExtensionId,
  setIsSTTSupported,
  setIsSTTv2Supported,
  setUserTrialData,
  getDayPassData,
  setPendingTrackEvent,
  clearPendingTrackEvents,
  fetchStudyChallengeData,
  setStopSTT,
  setIsSTTToggledOn,
  setIsSigningUp,
  setIsSigningIn,
  //auto notes
  setIsAutoNotesSupported,
  setIsWhisperAutoNotesSupported,

  // desktop
  setDesktopVersion,
  setIframePort,
  setDesktopDefaultCaptureRect,
  setDesktopCaptureRect,
  setSelectedSourceData,
  setIsGuestModeAlertShown,
  fetchGracePeriods,
  //tablet
  setIsTabletAppOpenedInSplitView,
  setIsUserCapturedByTabletBefore,
  setUserTabletDeviceInfo,
  setTabletOrientation,
};
const mapStateToProps = (state) => ({
  lang: state.slidGlobal.lang,
  applicationType: state.slidGlobal.applicationType,
  userData: state.slidGlobal.userData,
  notifications: state.slidGlobal.notifications,
  extensionVersion: state.slidGlobal.extensionVersion,
  errorMessage: state.slidGlobal.errorMessage,
  modalOn: state.modal.modalOn,
  isExtensionInstalled: state.slidGlobal.isExtensionInstalled,
  extensionId: state.slidGlobal.extensionId,
  pendingTrackEvents: state.slidGlobal.pendingTrackEvents,
  isSTTActive: state.sttReducer.isSTTActive,
  editorInstance: state.vdocs.editorInstance,
  isReadOnly: state.vdocs.isReadOnly,
  // desktop
  desktopVersion: state.slidGlobal.desktopVersion,
  iframePort: state.vdocs.iframePort,
});

export default withTranslation()(connect(mapStateToProps, actions)(withCookies(withHooks(App))));
